<template>
  <div v-if="loading"></div>
  <div v-else class="page">
     <div
      class="tagBall"
      ref="tagBall"
      @mouseover.self.stop="mouseOver"
      @mouseleave.self.stop="mouseLeave"
    >
      <!-- <p v-for="(item, index) in 50" :key="index" class="tag">{{ item }}</p> -->
      <p v-for="(item, index) in info.vector_list" :key="index" class="tag"  @click="click_word">{{ item.word }}</p>

    </div>
    <div class="desc">用词向量反应事物间的关系：<span>{{info.word}}</span></div>
     <p class="big_button"
         @click="want_loading_app"
         data-aos="slide-up">下载 向量单词 APP</p>
  </div>
</template>

<script>

import axios from 'axios'
import Utils from '../../utils/utils'
export default {
  name: 'Ball_test',
  components: {},
  data () {
    return {
      // vector_list: [{ id: 'A6ozAG', word: 'sea', d: 0.052227, e: 0.035589 }, { id: 'ARPCc7', word: 'coastal', d: 3.054268, e: 0.465362 }, { id: 'AHBhyT', word: 'ocean', d: 1.793908, e: 0.430983 }, { id: 'A84Z7g', word: 'coast', d: 4.96016, e: 0.448189 }, { id: 'ADYzym', word: 'marine', d: 0.573253, e: 0.454993 }, { id: 'BrozQR', word: 'crab', d: 0.539899, e: 0.95956 }, { id: 'BbaoZ8', word: 'tidal', d: 1.864275, e: 1.012008 }, { id: 'BDhqgj', word: 'whale', d: 0.071051, e: 0.982509 }, { id: 'AZz3mk', word: 'harbor', d: 4.433406, e: 0.96846 }, { id: 'BqnLYi', word: 'oceanic', d: 1.353708, e: 1.018681 }, { id: 'A8ik82', word: 'navy', d: 5.690242, e: 0.996406 }, { id: 'A67gmT', word: 'lake', d: 3.227983, e: 1.033184 }, { id: 'A4btBG', word: 'water', d: 3.049013, e: 1.43273 }, { id: 'B8ahua', word: 'polar', d: 1.238165, e: 1.388593 }, { id: 'ADRnEH', word: 'naval', d: 5.621342, e: 1.368374 }, { id: 'A3inc8', word: 'river', d: 3.800706, e: 1.338674 }, { id: 'AXUe4x', word: 'vessel', d: 5.083575, e: 1.427936 }, { id: 'AC3MVJ', word: 'beach', d: 3.726801, e: 1.798713 }, { id: 'AqwbbH', word: 'marsh', d: 3.205509, e: 1.849591 }, { id: 'A8jkqt', word: 'ship', d: 4.950477, e: 1.97958 }],
      info: [],
      colors: ['rgba(32, 179, 135, 1)', 'rgba(66, 185, 131, 1)', 'rgba(232, 114, 131, 1)', 'rgba(68, 206, 44, 1)', 'rgba(46, 171, 224, 1)', 'rgba(162, 193, 37, 1)', 'rgba(152, 223, 22, 1)', 'rgba(79, 193, 71, 1)', 'rgba(41, 184, 241, 0.8)'],
      loading: true,
      tagEle: '',
      paper: '',
      RADIUS: 150,
      fallLength: 300,
      tags: [],
      angleX: Math.PI / 300,
      angleY: Math.PI / 300,
      CX: '',
      CY: '',
      EX: '',
      EY: '',
      timing: null
    }
  },
  props: {
  },
  computed: {
    // maxum () {
    //   return this.wordList.sort()[0].value
    // }
  },
  watch: {},
  created () {},
  mounted () {
    this.get_index()
  },
  methods: {
    get_index () {
      var data = { id: 'PtWiFmk2' }
      axios.post('/word_card/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.info = res.data.data.info
          this.loading = false
          // for (var i = 0; i < res.data.data.info.vector_list.length; i++) {
          //   this.vector_list.push(res.data.data.info.vector_list[i])
          //   // console.log(res.data.data.info.vector_list[i])
          // }
          this.loading = false
          setTimeout(() => {
            this.tagEle = this.$refs.tagBall.children
            this.paper = this.$refs.tagBall
            this.CX = this.paper.offsetWidth / 2
            this.CY = this.paper.offsetHeight / 2
            this.EX = this.paper.offsetLeft + document.body.scrollLeft + document.documentElement.scrollLeft
            this.EY =
            this.paper.offsetTop + document.body.scrollTop + document.documentElement.scrollTop
            this.innit()
            this.animate()
          }, 5)
        })
    },
    innit () {
      for (var i = 0; i < this.tagEle.length; i++) {
        console.log(this.tagEle[i])
        var dAngle = this.info.vector_list[i].d
        var eAngle = this.info.vector_list[i].e
        var y = this.RADIUS * Math.sin(eAngle) * Math.sin(dAngle)
        var z = this.RADIUS * Math.cos(eAngle)
        var x = this.RADIUS * Math.sin(eAngle) * Math.cos(dAngle)
        // this.tagEle[i].style.color = 'rgb(' + parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255) + ')'
        if (i === 0) {
          this.tagEle[i].style.fontWeight = 'bold'
          this.tagEle[i].style.color = 'rgba(41, 184, 241, 0.8)'
        } else {
          this.tagEle[i].style.fontWeight = 360
          this.tagEle[i].style.color = 'rgb(' + parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255) + ')'

          // this.tagEle[i].style.color = this.colors[Math.floor(Math.random() * this.colors.length)]
        }
        this.tags.push({ ele: this.tagEle[i], x, y, z })
      }
    },
    tag ({ ele, x, y, z }) {
      var scale = this.fallLength / (this.fallLength - z)
      var alpha = (z + this.RADIUS) / (2 * this.RADIUS)
      ele.style.fontSize = 15 * scale + 'px'
      ele.style.opacity = alpha + 0.5
      ele.style.filter = 'alpha(opacity = ' + (alpha + 0.5) * 100 + ')'
      ele.style.zIndex = parseInt(scale * 100)
      ele.style.left = x + this.CX - ele.offsetWidth / 2 + 'px'
      ele.style.top = y + this.CY - ele.offsetHeight / 2 + 'px'
      // ele.style.color = this.colors[Math.floor(Math.random() * this.colors.length)]
      // ele.style.color = 'rgb(' + parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255) + ')'
    },
    animate () {
      this.timing = setInterval(() => {
        this.rotateX()
        this.rotateY()
        this.tags.forEach((item) => {
          this.tag(item)
        })
      }, 20)
    },
    rotateX () {
      var cos = Math.cos(this.angleX)
      var sin = Math.sin(this.angleX)
      this.tags.forEach((item) => {
        var y1 = item.y * cos - item.z * sin
        var z1 = item.z * cos + item.y * sin
        item.y = y1
        item.z = z1
      })
    },
    rotateY () {
      var cos = Math.cos(this.angleY)
      var sin = Math.sin(this.angleY)
      this.tags.forEach((item) => {
        var x1 = item.x * cos - item.z * sin
        var z1 = item.z * cos + item.x * sin
        item.x = x1
        item.z = z1
      })
    },
    mouseOver (event) {
      var x = event.clientX - this.EX - this.CX
      var y = event.clientY - this.EY - this.CY
      console.log(x, y)
      this.angleY = x * 0.0001
      this.angleX = y * 0.0001
      if (!this.timing) this.animate()
    },
    mouseLeave (event) {
      var x = event.clientX - this.EX - this.CX
      var y = event.clientY - this.EY - this.CY
      this.angleY = x * 0.0001
      this.angleX = y * 0.0001
    },
    cheMouseOver () {
      clearInterval(this.timing)
      this.timing = null
    },
    want_loading_app () {
      Utils.loading_app()
    },
    click_word () {
      Utils.show_snackbar('下载app查看详情')
    }
  }
}
</script>
<style scoped>
.page{
  position: fixed;
  width: 100%;
  height: 100%;
}
.tagBall {
  width: 320px;
  height: 320px;
  margin: 60px auto 60px auto;
  /* padding: 60px auto; */
  position: relative;
}
.tag {
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  color: #000;
  text-decoration: none;
  font-size: 15px;
  font-family: "微软雅黑";
  font-weight: bold;
}
.desc{
  color: rgba(41, 184, 241, 0.8);
  font-size: 16px;
}
.desc span{
  color: rgba(41, 184, 241, 0.8);
  font-size: 20px;
}
</style>
